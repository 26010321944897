import { createSchema } from "./index.js"

export const schemaChange = createSchema({
    schemaName: 'change',
    displayName: 'Change Log',
    collectionName: 'Change',
    icon: 'ico_change_log.svg',
    itemPath: 'updateTime',
    searchKeys: ['userId', 'itemId', 'type'],
    fields: [
        {
            name: 'Added Time',
            path: 'addTime',
            type: 'dateTime'
        },
        {
            name: 'Last Updated',
            path: 'updateTime',
            type: 'dateTime',
            sequence: 10
        },
        {
            name: 'User ID',
            type: 'text',
            path: 'userId',
            sequence: 2
        },
        {
            name: 'Collection',
            type: 'text',
            path: 'collection',
            sequence: 3
        },
        {
            name: 'Item ID',
            type: 'text',
            path: 'itemId',
            sequence: 4
        },
        {
            name: 'Path',
            type: 'text',
            path: 'path',
            sequence: 5
        },
        {
            name: 'New Value',
            type: 'text',
            path: 'value',
            sequence: 6,
            flex: 2
        },
        {
            name: 'Type',
            type: 'text',
            path: 'type',
            sequence: 7
        }
    ]
});
