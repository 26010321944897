import { createSchema } from "./index.js"

export const schemaLocation = createSchema({
    schemaName: 'location',
    displayName: 'Service Locations',
    collectionName: 'Location',
    icon: 'ico_service.svg',
    itemPath: 'name',
    searchKeys: ['remoteId', 'address.city', 'address.zip'],
    importSwitchIndex: 0,
    exportSwitchIndex: 4,
    defaultSort: {
        'address.state': 1
    },
    
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 6,
                    flex: 2
                },
                {
                    name: 'Last Published',
                    path: 'publishTime',
                    type: 'dateTime'
                }
            ]
        },
        {
            type: 'category',
            name: 'General',
            hideName: true,
            fields: [
                {
                    name: 'Remote ID',
                    type: 'text',
                    path: 'remoteId',
                    sequence: 1,
                    width: 50
                },
                {
                    name: 'Source',
                    type: 'text',
                    path: 'source',
                    width: 50
                },
                {
                    name: 'Name',
                    type: 'text',
                    path: 'name',
                    flex: 3,
                    suggestions: 0,
                    sequence: 2
                },
                {
                    name: 'Website',
                    type: 'text',
                    path: 'url',
                    flex: 3,
                    suggestions: 0,
                    sequence: 4,
                    width: 50
                },
                {
                    name: 'Email',
                    type: 'email',
                    path: 'email',
                    width: 50
                },
                {
                    name: 'Latitude',
                    type: 'number',
                    path: 'geoLoc.coordinates[1]',
                    width: 50
                },
                {
                    name: 'Longitude',
                    type: 'number',
                    path: 'geoLoc.coordinates[0]',
                    width: 50
                },
                {
                    name: 'Oasis',
                    type: 'boolean',
                    description: 'Oasis dealer?',
                    path: 'oasis',
                    sequence: 5
                },
                {
                    name: 'Elite Support',
                    type: 'boolean',
                    description: 'Elite support?',
                    path: 'eliteSupport'
                }
            ]
        },
        {
            name: 'Address',
            type: 'category',
            fields: [
                {
                    name: 'Street 1',
                    type: 'text',
                    path: 'address.street',
                    suggestions: 0
                },
                {
                    name: 'Street 2',
                    type: 'text',
                    path: 'address.apt',
                    suggestions: 0
                },
                {
                    name: 'City',
                    type: 'text',
                    path: 'address.city',
                    width: 50
                },
                {
                    name: 'State',
                    type: 'text',
                    path: 'address.state',
                    width: 25,
                    sequence: 3
                },
                {
                    name: 'Zip',
                    type: 'text',
                    path: 'address.zip',
                    width: 25
                },
                {
                    name: 'Country',
                    type: 'text',
                    path: 'address.country'
                }
            ]
        },
        {
            name: 'Phone',
            type: 'category',
            fields: [
                {
                    name: 'Main',
                    type: 'phone',
                    path: 'phone.main',
                    width: 25
                },
                {
                    name: 'Toll Free',
                    type: 'phone',
                    path: 'phone.tollFree',
                    width: 25
                },
                {
                    name: 'Fax',
                    type: 'phone',
                    path: 'phone.fax',
                    width: 25
                },
                {
                    name: 'Wrecker',
                    type: 'phone',
                    path: 'phone.wrecker',
                    width: 25
                }
            ]
        }
    ]
});

export const schemaDealerLocation = createSchema({
    schemaName: 'dealerLocation',
    displayName: 'Dealer Locations',
    collectionName: 'DealerLocation',
    icon: 'ico_dealer_loc.svg',
    itemPath: 'name',
    searchKeys: ['address.city', 'address.zip'],
    defaultSort: {
        'address.state': 1
    },
    
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 6,
                    flex: 2
                },
                {
                    name: 'Last Published',
                    path: 'publishTime',
                    type: 'dateTime'
                }
            ]
        },
        {
            type: 'category',
            name: 'General',
            hideName: true,
            fields: [
                {
                    name: 'Name',
                    type: 'text',
                    path: 'name',
                    flex: 3,
                    suggestions: 0,
                    sequence: 1,
                    width: 50
                },
                {
                    name: 'Category',
                    type: 'select',
                    path: 'category',
                    sequence: 2,
                    width: 50,
                    lineAfter: true,
                    options: [
                        {
                            value: "rvDealerships",
                            label: "RV"
                        },
                        {
                            value: "commercialDealerships",
                            label: "Commercial"
                        },
                        {
                            value: "busDealerships",
                            label: "Bus"
                        }
                    ]
                },
                {
                    name: 'Email',
                    type: 'email',
                    path: 'email',
                    width: 50
                },
                {
                    name: 'Website',
                    type: 'text',
                    path: 'url',
                    suggestions: 0,
                    width: 50
                },
                {
                    name: 'Latitude',
                    type: 'number',
                    path: 'coordinates.latitude',
                    width: 50
                },
                {
                    name: 'Longitude',
                    type: 'number',
                    path: 'coordinates.longitude',
                    width: 50
                },
                {
                    name: 'Tags (OEMs)',
                    type: 'list',
                    path: 'tags',
                    fields: [
                        {
                            name: 'Tags (OEMs)',
                            hideName: true,
                            type: 'text',
                            path: 'name',
                            width: 50,
                            sequence: 3,
                            linkedInfo: {
                                schema: 'oem',
                                path: 'name'
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'Address',
            type: 'category',
            fields: [
                {
                    name: 'Street 1',
                    type: 'text',
                    path: 'address.street',
                    suggestions: 0
                },
                {
                    name: 'Street 2',
                    type: 'text',
                    path: 'address.apt',
                    suggestions: 0
                },
                {
                    name: 'City',
                    type: 'text',
                    path: 'address.city',
                    width: 50,
                    sequence: 4
                },
                {
                    name: 'State',
                    type: 'text',
                    path: 'address.state',
                    width: 25,
                    sequence: 5
                },
                {
                    name: 'Zip',
                    type: 'text',
                    path: 'address.zip',
                    width: 25
                },
                {
                    name: 'Country',
                    type: 'text',
                    path: 'address.country'
                }
            ]
        },
        {
            name: 'Phone',
            type: 'category',
            fields: [
                {
                    name: 'Main',
                    type: 'phone',
                    path: 'phone.main',
                    width: 25
                },
                {
                    name: 'Toll Free',
                    type: 'phone',
                    path: 'phone.tollFree',
                    width: 25
                }
            ]
        }
    ]
});
