import { createSchema } from "./index.js"

export const schemaWarrantyRecord = createSchema({
    schemaName: 'warrantyRecord',
    displayName: 'Warranty Records',
    collectionName: 'WarrantyRecord',
    icon: 'ico_warranty.svg',
    itemPath: 'vin',
    searchKeys: [ 'registeredCustomerName', 'registeredDealer', 'registeredDealerName'],
    fields: [
        {
            name: 'VIN',
            type: 'text',
            path: 'vin',
            csvColumn: "VIN #",
            suggestions: 0,
            width: 50
        },
        // serialNum is already last 6 of VIN
        // {
        //     name: "Last 6 of VIN",
        //     type: "text",
        //     path: "vinShort",
        //     suggestions: 0,
        //     sequence: 1,
        //     width: 50
        // },
        {
            name: "Serial #",
            type: "text",
            path: "serialNum",
            csvColumn: "SERIAL #",
            sequence: 1
        },
        {
            name: "Vehicle Make",
            type: "text",
            path: "vehicleMake",
            csvColumn: "VEHICLE MAKE",
        },
        {
            name: "Product Type",
            type: "text",
            path: "productType",
            csvColumn: "PRODUCT TYPE",
        },
        {
            name: "Base Model",
            type: "text",
            path: "baseModel",
            csvColumn: "BASE MODEL",
        },
        {
            name: "Model Name",
            type: "text",
            path: "modelName",
            csvColumn: "MODEL NAME",
        },
        {
            name: "In-Svc Date",
            type: "text",
            path: "inSvcDate",
            csvColumn: "IN-SVC DATE",
        },
        {
            name: "In-Svc Update Date",
            type: "text",
            path: "inSvcUpdateDate",
            csvColumn: "IN-SVC UPDATE DATE",
        },
        {
            name: "Build Date",
            type: "text",
            path: "buildDate",
            csvColumn: "BUILD DATE",
        },
        {
            name: "Domicile Country",
            type: "text",
            path: "domicileCountry",
            csvColumn: "DOMICILE COUNTRY",
        },
        {
            name: "Ordering Dealer",
            type: "text",
            path: "orderingDealer",
            csvColumn: "ORDERING DEALER",
        },
        {
            name: "Ordering Dealer Name",
            type: "text",
            path: "orderingDealerName",
            csvColumn: "ORDERING DEALER NAME",
        },
        {
            name: "Registered Dealer",
            type: "text",
            path: "registeredDealer",
            csvColumn: "REGISTERED DEALER",
        },
        {
            name: "Registered Dealer Name",
            type: "text",
            path: "registeredDealerName",
            csvColumn: "REGISTERED DEALER NAME",
        },
        {
            name: "Pdi Date",
            type: "text",
            path: "pdiDate",
            csvColumn: "PDI DATE",
        },
        {
            name: "Ordering Customer",
            type: "text",
            path: "orderingCustomer",
            csvColumn: "ORDERING CUSTOMER",
        },
        {
            name: "Ordering Customer Name",
            type: "text",
            path: "orderingCustomerName",
            csvColumn: "ORDERING CUSTOMER NAME",
        },
        {
            name: "Ordering Customer Address",
            type: "text",
            path: "orderingCustomerAddress",
            csvColumn: "ORDERING CUSTOMER ADDRESS",
        },
        {
            name: "Ordering Customer City",
            type: "text",
            path: "orderingCustomerCity",
            csvColumn: "ORDERING CUSTOMER CITY",
        },
        {
            name: "Ordering Customer State",
            type: "text",
            path: "orderingCustomerState",
            csvColumn: "ORDERING CUSTOMER STATE",
        },
        {
            name: "Ordering Customer Zip",
            type: "text",
            path: "orderingCustomerZip",
            csvColumn: "ORDERING CUSTOMER ZIP",
        },
        {
            name: "Registered Customer",
            type: "text",
            path: "registeredCustomer",
            csvColumn: "REGISTERED CUSTOMER",
        },
        {
            name: "Registered Customer Name",
            type: "text",
            path: "registeredCustomerName",
            csvColumn: "REGISTERED CUSTOMER NAME",
            sequence: 2
        },
        {
            name: "Registered Customer Address",
            type: "text",
            path: "registeredCustomerAddress",
            csvColumn: "REGISTERED CUSTOMER ADDRESS",
        },
        {
            name: "Registered Customer City",
            type: "text",
            path: "registeredCustomerCity",
            csvColumn: "REGISTERED CUSTOMER CITY",
            sequence: 3
        },
        {
            name: "Registered Customer State",
            type: "text",
            path: "registeredCustomerState",
            csvColumn: "REGISTERED CUSTOMER STATE",
            sequence: 4
        },
        {
            name: "Registered Customer Zip",
            type: "text",
            path: "registeredCustomerZip",
            csvColumn: "REGISTERED CUSTOMER ZIP",
        },
        {
            name: "Retail Sold Date",
            type: "text",
            path: "retailSoldDate",
            csvColumn: "RETAIL SOLD DATE",
            sequence: 5
        },
        {
            name: "Registration Date",
            type: "text",
            path: "registrationDate",
            csvColumn: "REGISTRATION DATE",
            sequence: 6
        }
    ]
});