import { createSchema } from "./index.js"

export const schemaFile = createSchema({
    schemaName: 'file',
    displayName: 'Files',
    collectionName: 'File',
    icon: 'ico_files.svg',
    itemPath: 'name',
    searchKeys: ['keywords', 'filename'],
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Added Time',
                    path: 'addTime',
                    type: 'dateTime'
                },
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 10
                }
            ]
        },
        {
            name: 'Name',
            type: 'text',
            path: 'name',
            sequence: 1
        },
        {
            name: 'Keywords',
            type: 'text',
            path: 'keywords',
            sequence: 2
        },
        {
            name: 'Filename',
            type: 'text',
            path: 'filename',
            sequence: 3
        },
        {
            name: 'md5',
            type: 'text',
            path: 'md5'
        },
        {
            name: 'Size',
            type: 'int',
            path: 'size'
        },
        {
            name: 'Protected',
            description: 'Protected',
            type: 'boolean',
            path: 'protected',
            sequence: 8
        },
        {
            name: 'URL',
            type: 'url',
            path: 'url'
        },
        {
            name: 'Upload',
            type: 'upload'
        }
    ]
});
