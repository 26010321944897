import { createSchema } from "./index.js"

export const schemaAssetCategory = createSchema({
    schemaName: 'assetCategory',
    displayName: 'Asset Categories',
    collectionName: 'AssetCategory',
    icon: 'ico_asset_categories.svg',
    itemPath: 'name',
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Added Time',
                    path: 'addTime',
                    type: 'dateTime'
                },
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 4
                }
            ]
        },
        {
            name: 'Name',
            type: 'text',
            path: 'name',
            sequence: 1
        },
        {
            name: 'Key',
            type: 'text',
            path: 'key',
            sequence: 2
        },
        {
            name: 'Parent',
            type: 'text',
            path: 'parent',
            sequence: 3
        },
        {
            type: 'category',
            name: 'Allowed Roles',
            fields: [
                {
                    type: 'note',
                    note: 'If all roles are unchecked, this category will be available to everyone'
                },
                {
                    name: 'Developer',
                    description: 'Developer',
                    type: 'boolean',
                    path: 'allowedRoles.developer'
                },
                {
                    name: 'Camp Freightliner I Graduate',
                    description: 'Camp Freightliner I Graduate',
                    type: 'boolean',
                    path: 'allowedRoles.Role:CampFreightlinerIGraduate'
                }
            ]
        },
        {
            name: 'Assets',
            type: 'category',
            fields: [
                {
                    name: 'Linked Assets',
                    type: 'list',
                    hideName: true,
                    path: 'linkedAssets',
                    fields: [
                        {
                            name: 'Asset',
                            type: 'text',
                            path: 'name',
                            linkedInfo: {
                                schema: 'asset',
                                path: 'name'
                            }
                        }
                    ]
                }
            ]
        },
        {
            name: 'Legacy Assets',
            type: 'category',
            fields: [
                {
                    name: 'Legacy Assets',
                    hideName: true,
                    type: 'list',
                    path: 'assets',
                    fields: [
                        {
                            name: 'Asset Name',
                            width: 50,
                            path: 'name'
                        },
                        {
                            name: 'Upload',
                            type: 'asset',
                            path: 'asset',
                            width: 50
                        }
                    ]
                },
            ]
        },
    ]
});
