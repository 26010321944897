import { createSchema } from "./index.js";

export const schemaNotification = createSchema({
	schemaName: 'notification',
	displayName: 'Push Notifications',
	collectionName: 'notifications',
	itemPath: 'title',
	defaultSort: {
		scheduledFor: -1
	},
	fields: [
		{
			type: 'text',
			name: 'Title',
			path: 'title',
			sequence: 0
		},
		{
			type: 'textarea',
			name: 'Body',
			path: 'body'
		},
		{
			type: 'date',
			name: 'Scheduled For',
			path: 'scheduledFor',
			sequence: 1
		}
	]
});
