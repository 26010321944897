import { createSchema } from "./index.js"

export const schemaAsset = createSchema({
    schemaName: 'asset',
    displayName: 'Assets',
    collectionName: 'Asset',
    icon: 'ico_assets.svg',
    itemPath: 'name',
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Added Time',
                    path: 'addTime',
                    type: 'dateTime'
                },
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 4
                }
            ]
        },
        {
            name: 'Name',
            type: 'text',
            path: 'name',
            sequence: 3,
            width: 50
        },
        { 
            name: 'Type',
            type: 'select',
            path: 'assetType',
            options: ['PDF', 'LINK', 'IMAGE', 'VIDEO', 'TEXT'],
            default: 'LINK',
            sequence: 2,
            width: 50,
            lineAfter: true
        },
        {
            name: 'Thumbnail (if Link or Guide)',
            type: 'img',
            path: 'thumbnail',
            // width: 50,
            sequence: 1,
            showWhen: ({item}) => item.assetType !== 'IMAGE'
        },
        {
            name: 'URL (if Link)',
            type: 'url',
            path: 'url',
            sequence: 4,
            showWhen: ({item}) => item.assetType === 'LINK'
        },
        {
            name: 'File (if not Link)',
            type: 'upload',
            path: 'file',
            lineAfter: true
            // width: 50
        },
        {
            name: 'Text Content',
            type: 'textarea',
            path: 'textContent',
        },
        // {
        //     name: 'Caption (optional)',
        //     type: 'text',
        //     path: 'caption'
        // },
        // {
        //     name: 'Preview',
        //     type: 'img',
        //     path: 'preview',
        //     width: 50
        // }
        {
            name: 'Custom Fields',
            type: 'keyValueList',
            path: 'customFields'
        }
    ]
});
