import { createSchema } from "./index.js"

export const schemaFaq = createSchema({
    schemaName: 'faq',
    displayName: 'FAQs',
    collectionName: 'FAQ',
    icon: 'ico_faqs.svg',
    itemPath: 'sequence',
    searchKeys: ['question', 'answer'],
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Added Time',
                    path: 'addTime',
                    type: 'dateTime'
                },
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime'
                }
            ]
        },
        {
            name: 'Sequence',
            type: 'number',
            path: 'sequence',
            suggestions: 0,
            sequence: 1
        },
        {
            name: 'Question',
            type: 'text',
            path: 'question',
            suggestions: 0,
            sequence: 2,
            flex: 4
        },
        {
            name: 'Answer',
            type: 'text',
            path: 'answer',
            suggestions: 0,
            sequence: 3,
            flex: 4
        }
    ]
});
