import { createSchema } from "./index.js"

export const schemaOem = createSchema({
    schemaName: 'oem',
    displayName: 'OEMs',
    collectionName: 'OEM',
    icon: 'ico_oem.svg',
    itemPath: 'name',
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 5
                },
                {
                    name: 'Last Published',
                    path: 'publishTime',
                    type: 'dateTime'
                }
            ]
        },
        {
            type: 'category',
            name: 'General',
            hideName: true,
            fields: [
                {
                    name: 'Name',
                    type: 'text',
                    path: 'name',
                    suggestions: 0,
                    sequence: 2,
                    flex: 3
                }
            ]
        },
        {
            type: 'category',
            name: 'URLs',
            fields: [
                {
                    name: 'Dealer Locator',
                    type: 'url',
                    path: 'urls.dealerLocatorUrl',
                    suggestions: 0
                }
            ]
        },
        {
            type: 'category',
            name: 'Images',
            fields: [
                {
                    name: 'Logo',
                    type: 'img',
                    path: 'images.logo',
                    sequence: 1
                },
                {
                    name: 'RV Advisor Logo (300 x ? .png)',
                    type: 'text',
                    path: 'temp.rvAdvisorLogoUrl',
                    suggestions: 0
                },
                {
                    name: 'General Logo URL',
                    type: 'url',
                    path: 'temp.logoUrl',
                    suggestions: 0
                }
            ]
        }
    ]
});
