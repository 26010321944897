import { createSchema } from "./index.js"

export const schemaModelSeries = createSchema({
    schemaName: 'modelSeries',
    displayName: 'Chassis (Model Series)',
    icon: 'ico_chassis.svg',
    collectionName: 'ModelSeries',
    itemPath: 'name',
    defaultSort: {
        name: 1
    },
    
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 4
                },
                {
                    name: 'Last Published',
                    path: 'publishTime',
                    type: 'dateTime'
                }
            ]
        },
        {
            type: 'category',
            name: 'General',
            hideName: true,
            fields: [
                {
                    name: 'Name',
                    type: 'text',
                    path: 'name',
                    suggestions: 0,
                    sequence: 2,
                    width: 25
                },
                {
                    name: 'Chassis Family',
                    type: 'text',
                    path: 'parent',
                    suggestions: 0,
                    sequence: 3,
                    width:25,
                    linkedInfo: {
                        schema: 'modelSeries',
                        path: 'name'
                    }
                },
                {
                    name: 'Description',
                    type: 'textarea',
                    path: 'description'
                }
            ]
        },
        {
            type: 'category',
            name: 'Media',
            fields: [
                {
                    name: 'Logo (.png)',
                    type: 'img',
                    path: 'images.logo',
                    sequence: 1
                },
                {
                    name: 'Specsheet Logo URL',
                    type: 'url',
                    path: 'temp.logoUrl',
                    suggestions: 0
                }
            ]
        },
        {
            name: 'Features',
            type: 'category',
            fields: [
                {
                    name: 'V-Ride',
                    img: 'https://cdn.fccchq.com/features/vride_logo.png',
                    type: 'boolean',
                    allowOne: [
                        'features.vSeries',
                    ],
                    path: 'features.vRide',
                    width: 16.6
                },
                {
                    name: 'V-Series',
                    img: 'https://cdn.fccchq.com/features/v-series-logo.png',
                    type: 'boolean',
                    allowOne: [
                        'features.vRide',
                    ],
                    path: 'features.vSeries',
                    width: 16.6
                },
                {
                    name: '60 Degree',
                    img: 'https://cdn.fccchq.com/features/60degrees_logo.png',
                    type: 'boolean',
                    path: 'features.60Degree',
                    width: 16.6
                },
                {
                    name: 'UltraSteer',
                    img: 'https://cdn.fccchq.com/features/ultrasteer_logo.png',
                    type: 'boolean',
                    path: 'features.ultraSteer',
                    width: 16.6
                },
                {
                    name: 'OptiView',
                    img: 'https://cdn.fccchq.com/features/OptiView_black.png',
                    type: 'boolean',
                    path: 'features.optiView',
                    width: 16.6
                },
                {
                    name: 'RoadWatch',
                    img: 'https://cdn.fccchq.com/features/RoadWatch.png',
                    type: 'boolean',
                    path: 'features.roadWatch',
                    width: 16.6
                },
                {
                    name: 'DriveTech',
                    img: 'https://cdn.fccchq.com/features/DriveTech.png',
                    type: 'boolean',
                    path: 'features.driveTech',
                    width: 16.6
                }
            ]
        },
        {
            name: 'Kiosk Content',
            type: 'category',
            fields: [
                {
                    name: 'Illustration (.png)',
                    type: 'img',
                    path: 'kiosk.images.illustration',
                    lineAfter: true
                },
                {
                    name: 'Kiosk Document Title',
                    type: 'text',
                    path: 'kiosk.documentTitle',
                    width: 50
                },
                {
                    name: 'Kiosk Document URL',
                    type: 'url',
                    path: 'kiosk.documentUrl',
                    width: 50,
                    lineAfter: true
                },
                // {
                //     name: 'Specs Overview',
                //     type: 'textarea',
                //     path: 'kiosk.specs.overview',
                //     lineAfter: true
                // },
                // {
                //     name: 'Engine Image',
                //     type: 'img',
                //     path: 'kiosk.images.engine',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Engine Content',
                //     type: 'textarea',
                //     path: 'kiosk.engine',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
                // {
                //     name: 'Transmission Image',
                //     type: 'img',
                //     path: 'kiosk.images.transmission',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Transmission Content',
                //     type: 'textarea',
                //     path: 'kiosk.transmission',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
                // {
                //     name: 'Rear Suspension Image',
                //     type: 'img',
                //     path: 'kiosk.images.rearSuspension',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Rear Suspension Content',
                //     type: 'textarea',
                //     path: 'kiosk.rearSuspension',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
                // {
                //     name: 'Brakes Image',
                //     type: 'img',
                //     path: 'kiosk.images.brakes',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Brakes Content',
                //     type: 'textarea',
                //     path: 'kiosk.brakes',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
                // {
                //     name: 'Frame Rails Image',
                //     type: 'img',
                //     path: 'kiosk.images.frameRails',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Frame Rails Content',
                //     type: 'textarea',
                //     path: 'kiosk.frameRails',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
                // {
                //     name: 'Fuel Tank Image',
                //     type: 'img',
                //     path: 'kiosk.images.fuelTank',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Fuel Tank Content',
                //     type: 'textarea',
                //     path: 'kiosk.fuelTank',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
                // {
                //     name: 'Steering Image',
                //     type: 'img',
                //     path: 'kiosk.images.steering',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Steering Content',
                //     type: 'textarea',
                //     path: 'kiosk.steering',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
                // {
                //     name: 'Front Suspension Image',
                //     type: 'img',
                //     path: 'kiosk.images.frontSuspension',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Front Suspension Content',
                //     type: 'textarea',
                //     path: 'kiosk.frontSuspension',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
                // {
                //     name: 'Shocks Image',
                //     type: 'img',
                //     path: 'kiosk.images.shocks',
                //     width: 33.3333333
                // },
                // {
                //     name: 'Shocks Content',
                //     type: 'textarea',
                //     path: 'kiosk.shocks',
                //     width: 66.6666666,
                //     lineAfter: true
                // },
            ]
        }
    ]
});
