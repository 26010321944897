import { createSchema } from "./index.js"

export const schemaUser = createSchema({
    schemaName: 'user',
    displayName: 'Users',
    collectionName: 'users',
    icon: 'ico_users.svg',
    itemPath: 'email',
    searchKeys: ['firstName', 'lastName', 'ownership.vin'],
    defaultSort: {
        updateTime: -1
    },
    
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Added Time',
                    path: 'addTime',
                    type: 'dateTime'
                },
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 10
                }
            ]
        },
        {
            name: 'First Name',
            type: 'text',
            path: 'firstName',
            suggestions: 0,
            sequence: 2
        },
        {
            name: 'Last Name',
            type: 'text',
            path: 'lastName',
            suggestions: 0,
            sequence: 1
        },
        {
            name: 'Email',
            type: 'text',
            path: 'email',
            suggestions: 0,
            sequence: 2
        },
        {
            name: 'Password',
            type: 'password'
        },
        {
            type: 'category',
            name: 'Permissions',
            fields: [
                {
                    name: 'Developer',
                    type: 'booleanList',
                    path: 'permissions',
                    value: 'developer',
                    width: 18
                },
                {
                    name: 'Model Manager Full Permissions',
                    type: 'booleanList',
                    path: 'permissions',
                    value: 'Collection:Model',
                    width: 18
                },
                {
                    name: 'Model Manager View',
                    type: 'booleanList',
                    path: 'permissions',
                    value: 'Collection:Model:Read',
                    width: 18
                },
                // {
                //     name: 'Custom Class Users Full Permissions',
                //     type: 'booleanList',
                //     path: 'permissions',
                //     value: 'Collection:CustomClassUser',
                //     width: 18
                // },
                {
                    name: 'Camp Freightliner I Graduate',
                    type: 'booleanList',
                    path: 'permissions',
                    value: 'Role:CampFreightlinerIGraduate',
                    width: 18
                }
            ]
        },
        {
            name: 'Additional Profile Info',
            type: 'category',
            fields: [
                {
                    name: 'Birthday',
                    type: 'text',
                    path: 'birthday',
                    width: 50
                },
                {
                    name: 'Company',
                    type: 'text',
                    path: 'company',
                    width: 50
                },
                {
                    name: 'Phone Number',
                    type: 'text',
                    path: 'phone',
                    suggestions: 0,
                    width: 50
                },
                {
                    name: 'Camp Freightliner I Graduation Date',
                    type: 'date',
                    path: 'graduationDateCFI'
                }
            ]
        },
        {
            name: 'Primary Address',
            type: 'category',
            fields: [
                {
                    name: 'Street',
                    type: 'text',
                    path: 'primaryAddress.street',
                    suggestions: 0,
                },
                {
                    name: 'City',
                    type: 'text',
                    path: 'primaryAddress.city',
                    width: 50
                },
                {
                    name: 'State',
                    type: 'text',
                    path: 'primaryAddress.state',
                    width: 25
                },
                {
                    name: 'ZIP Code',
                    type: 'text',
                    path: 'primaryAddress.zip',
                    sequence: 5,
                    width: 25
                }
            ]
        },
        {
            name: 'Ownership',
            type: 'category',
            fields: [
                {
                    name: 'VIN',
                    type: 'text',
                    path: 'ownership.vin',
                    width: 50
                },
                {
                    name: 'Engine Make',
                    type: 'select',
                    options: ['Cummins', 'Detroit Diesel'],
                    path: 'ownership.engine.make',
                    width: 50
                },
                {
                    name: 'Engine HP',
                    type: 'select',
                    options: ['450 or less', '500 or more'],
                    path: 'ownership.engine.hp',
                    width: 50
                },
                {
                    name: 'Transmission',
                    type: 'select',
                    options: ['Allison 1000', 'Allison 2000','Allison 3000','Allison 4000'],
                    path: 'ownership.transmission.make',
                    width: 50
                },
                {
                    name: 'Transmission Fluid Type',
                    type: 'select',
                    options: ['Synthetic','Dexron-III'],
                    path: 'ownership.transmission.fluidType',
                    width: 50
                },
                {
                    name: 'Purchase Date',
                    type: 'date',
                    path: 'ownership.purchaseDate',
                    width: 50
                },
                {
                    name: 'Chassis Manufacture Date',
                    type: 'date',
                    path: 'ownership.chassisManufactureDate',
                    width: 50
                },
                {
                    name: 'Make',
                    type: 'text',
                    path: 'ownership.make',
                    width: 50
                },
                {
                    name: 'Model',
                    type: 'text',
                    path: 'ownership.model',
                    width: 50
                },
                {
                    name: 'Year',
                    type: 'text',
                    path: 'ownership.year',
                    width: 50
                },
                {
                    name: 'FCOC Member',
                    description: 'Is this customer an FCOC Member?',
                    type: 'boolean',
                    path: 'ownership.fcocMember',
                    width: 50
                },
                {
                    name: 'FCOC Member Since',
                    type: 'text',
                    path: 'ownership.fcocMemberSince',
                    width: 50
                },
                {
                    name: 'Reset Ownership',
                    type: 'reset',
                    path: 'ownership',
                    value: null
                }
            ]
        },
        {
            name: 'Analytics/Metrics',
            type: 'category',
            fields: [
                {
                    name: 'Kiosks Visited',
                    type: 'list',
                    path: 'analytics.kiosks',
                    fields: [
                        {
                            name: 'Kiosk Code',
                            columnName: 'Kiosks Visited',
                            path: 'code',
                            sequence: 6,
                            width: 50
                        }
                    ]
                },
                {
                    name: 'App Events',
                    type: 'list',
                    path: 'analytics.app',
                    fields: [
                        {
                            name: 'App Event',
                            path: 'action',
                            // sequence: 6,
                            width: 50
                        },
                        {
                            name: 'Date',
                            path: 'date',
                            // type: 'date',
                            // sequence: 6,
                            width: 50
                        }
                    ]
                }
            ]
        },
        {
            name: 'Maintenance History',
            type: 'category',
            fields: [
                {
                    name: 'Maintenance History',
                    type: 'list',
                    path: 'ownership.maintenanceHistory',
                    fields: [
                        {
                            name: 'ActionID',
                            path: 'actionId',
                            // sequence: 6,
                            width: 50
                        },
                        {
                            name: 'Date Performed',
                            path: 'datePerformed',
                            // sequence: 6,
                            // type: 'date',
                            width: 50
                        }
                    ]
                }
            ]
        }
    ]
});
