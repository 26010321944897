import { createSchema } from "./index.js"

export const schemaKiosk = createSchema({
    schemaName: 'kiosk',
    displayName: 'Kiosks',
    collectionName: 'Kiosk',
    icon: 'ico_kiosk.svg',
    itemPath: 'code',
    searchKeys: ['name, type'],
    
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Added Time',
                    path: 'addTime',
                    type: 'dateTime'
                },
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 6
                }
            ]
        },
        {
            name: 'Code',
            type: 'text',
            path: 'code',
            suggestions: 0,
            sequence: 1
        },
        {
            name: 'Name',
            note: 'You can name this kiosk for your own convenience',
            type: 'text',
            path: 'name',
            suggestions: 0,
            sequence: 2,
            flex: 4
        },
        {
            name: 'Type',
            type: 'text',
            path: 'type',
            linkedInfo: {
                schema: 'kioskType',
                path: 'name'
            },
            sequence: 3,
            width: 50
        },
        {
            name: 'Default Chassis',
            type: 'text',
            path: 'defaultChassis',
            linkedInfo: {
                schema: 'modelSeries',
                path: 'name'
            },
            sequence: 4,
            width: 50
        },
        {
            name: 'Last Pinged',
            type: 'dateTime',
            path: 'lastPinged',
            sequence: 5,
        },
        {
            name: 'Computer Name',
            type: 'text',
            path: 'computerName',
            sequence: 6,
        }
    ]
});

// export const schemaCustomClassUser = {
//     schemaName: 'customClassUser',
//     displayName: 'Custom Class Users',
//     collectionName: 'CustomClassUser',
//     itemPath: 'nfcId',
//     searchKeys: ['firstName', 'lastName', 'company', 'email', 'ownership.vin'],
//     fields: [
//         {
//             name: 'NFC ID',
//             type: 'text',
//             path: 'nfcId',
//             suggestions: 0,
//             sequence: 1,
//             lineAfter: true
//         }
//     ]
// };


export const schemaKioskType = createSchema({
    schemaName: 'kioskType',
    displayName: 'Kiosk Types',
    collectionName: 'KioskType',
    icon: 'ico_kiosk_types.svg',
    itemPath: 'name',
    
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Added Time',
                    path: 'addTime',
                    type: 'dateTime'
                },
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 6
                }
            ]
        },
        {
            name: 'Name',
            type: 'text',
            path: 'name',
            suggestions: 0,
            sequence: 1,
            width: 50
        },
        {
            name: 'Show Name',
            type: 'text',
            path: 'showName',
            suggestions: 0,
            sequence: 1,
            width: 50
        },
        {
            name: 'Show on Kiosk',
            description: "Show on kiosk",
            type: 'boolean',
            path: 'visible',
            sequence: 2,
            width: 50
        },
        {
            name: 'Styling',
            type: 'category',
            fields: [
                {
                    name: 'Logo',
                    type: 'img',
                    path: 'styling.logo'
                },
                {
                    name: 'Browse Graphic',
                    type: 'img',
                    path: 'styling.browseGraphic'
                },
                {
                    name: 'Register Graphic',
                    type: 'img',
                    path: 'styling.registerGraphic'
                },
                {
                    name: "Only Show Logo",
                    description: "Only show the logo on the home screen",
                    type: 'boolean',
                    path: 'styling.onlyShowLogo'
                },
                {
                    name: "Only Show Browse Graphic",
                    description: "Only show the browse graphic on the home screen top right corner",
                    type: 'boolean',
                    path: 'styling.onlyShowBrowseGraphic'
                },
                {
                    name: "Only Show Register Graphic",
                    description: "Only show the register graphic on the home screen bottom right corner",
                    type: 'boolean',
                    path: 'styling.onlyShowRegisterGraphic'
                },
                // {
                //     name: 'Logo Width',
                //     description: "Specify a custom logo width (defaults to 1024px)",
                //     type: 'number',
                //     path: 'styling.logoWidth'
                // }
                // {
                //     name: 'Background Color',
                //     type: 'color',
                //     path: 'styling.backgroundColor'
                // }
            ]
        },
        {
            name: 'Attract Loop',
            type: 'category',
            fields: [
                {
                    name: 'Video URL',
                    note: 'If blank, kiosk will use default local video file',
                    type: 'url',
                    path: 'attractLoop.videoUrl'
                },
                {
                    name: 'Overlay Background Opacity (0.0 - 1.0)',
                    note: 'The higher the opacity, the more the overlay will darken the video behind it. (default: 0.5)',
                    type: 'number',
                    path: 'attractLoop.overlayBackgroundOpacity',
                    lineAfter: true
                },
                {
                    name: 'Use Logo for Video Overlay?',
                    description: 'Use Logo for Video Overlay?',
                    type: 'boolean',
                    path: 'attractLoop.useLogoForVideoOverlay'
                },
                {
                    name: 'Hide Text',
                    description: 'Hide Text',
                    type: 'boolean',
                    path: 'attractLoop.hideText'
                },
                {
                    name: 'Hide Overlay',
                    description: 'Hide Overlay',
                    type: 'boolean',
                    path: 'attractLoop.hideOverlay'
                }
            ]
        },
        {
            name: 'Content',
            type: 'category',
            fields: [
                {
                    name: 'OEMs',
                    //hideName: true,
                    type: 'list',
                    path: 'content.oems',
                    fields: [
                        {
                            name: 'OEM',
                            hideName: true,
                            type: 'text',
                            path: 'name',
                            width: 50,
                            linkedInfo: {
                                schema: 'oem',
                                path: 'name'
                            }
                        }
                    ]
                },
                // {
                //     name: 'Chassis (Model Series)',
                //     //hideName: true,
                //     type: 'list',
                //     path: 'content.chassis',
                //     fields: [
                //         {
                //             name: 'Chassis (Model Series)',
                //             hideName: true,
                //             type: 'text',
                //             path: 'name',
                //             width: 50,
                //             linkedInfo: {
                //                 schema: 'modelSeries',
                //                 path: 'name'
                //             }
                //         }
                //     ]
                // },
                {
                    name: 'Model Name',
                    //hideName: true,
                    type: 'list',
                    path: 'content.modelName',
                    fields: [
                        {
                            name: 'Model Name',
                            hideName: true,
                            type: 'text',
                            path: 'name',
                            width: 50,
                            linkedInfo: {
                                schema: 'modelName',
                                path: 'name'
                            }
                        }
                    ]
                },
                {
                    name: 'Model Years',
                    type: 'list',
                    path: 'content.modelYears',
                    fields: [
                        {
                            name: 'Model Year',
                            hideName: true,
                            type: 'number',
                            path: 'year',
                            width: 50
                        }
                    ]
                }
            ]
        },
        // {
        //     name: 'Contact',
        //     type: 'category',
        //     fields: [
        //         {
        //             name: 'Name',
        //             type: 'text',
        //             path: 'contact.name',
        //             suggestions: 0
        //         },
        //         {
        //             name: 'Email',
        //             type: 'email',
        //             path: 'contact.email',
        //             suggestions: 0
        //         }
        //     ]
        // },
        // {
        //     name: 'Location',
        //     type: 'category',
        //     fields: [
        //         {
        //             name: 'Street 1',
        //             type: 'text',
        //             path: 'address.street',
        //             suggestions: 0
        //         },
        //         {
        //             name: 'Street 2',
        //             type: 'text',
        //             path: 'address.apt',
        //             suggestions: 0
        //         },
        //         {
        //             name: 'City',
        //             type: 'text',
        //             path: 'address.city',
        //             width: 50
        //         },
        //         {
        //             name: 'State',
        //             type: 'text',
        //             path: 'address.state',
        //             width: 25
        //         },
        //         {
        //             name: 'Zip',
        //             type: 'text',
        //             path: 'address.zip',
        //             width: 25
        //         },
        //         {
        //             name: 'Country',
        //             type: 'text',
        //             path: 'address.country'
        //         }
        //     ]
        // },
    ]
});

export const schemaKioskAnalytics = createSchema({
    schemaName: 'kioskAnalytics',
    displayName: 'Kiosk Analytics',
    collectionName: 'KioskAnalytics',
    icon: 'ico_kiosk_analytics.svg',
    itemPath: 'event.label',
    searchKeys: ['code'],
    
    fields: [
        {
            name: 'Last Updated',
            path: 'updateTime',
            type: 'dateTime',
            hide: true,
            sequence: 1
        },
        {
            name: 'Kiosk Code',
            type: 'text',
            path: 'code',
            linkedInfo: {
                schema: 'kiosk',
                path: 'code'
            },
            suggestions: 0,
            sequence: 2,
            lineAfter: true
        },
        {
            name: 'Category',
            type: 'text',
            path: 'event.category',
            sequence: 3,
            width: 25
        },
        {
            name: 'Action',
            type: 'text',
            path: 'event.action',
            sequence: 4,
            width: 25
        },
        {
            name: 'Label',
            type: 'text',
            path: 'event.label',
            sequence: 5,
            width: 25
        },
        {
            name: 'Value',
            type: 'number',
            path: 'event.value',
            sequence: 6,
            width: 25
        }
    ]
});
