import { createSchema } from "./index.js"

export const schemaFeature = createSchema({
    schemaName: 'features',
    displayName: '5-Star Features',
    collectionName: 'Feature',
    icon: 'ico_5star.svg',
    itemPath: 'name',
    searchKeys: [],
    fields: [
        {
            type: 'category',
            name: 'Meta',
            hide: true,
            fields: [
                {
                    name: 'Last Updated',
                    path: 'updateTime',
                    type: 'dateTime',
                    sequence: 4
                },
                {
                    name: 'Last Published',
                    path: 'publishTime',
                    type: 'dateTime'
                }
            ]
        },
        {
            type: 'category',
            name: 'General',
            hideName: true,
            fields: [
                {
                    name: 'Name',
                    type: 'text',
                    path: 'name',
                    suggestions: 0,
                    sequence: 2
                },
                {
                    name: 'Tagline',
                    type: 'text',
                    path: 'tagline',
                    suggestions: 0,
                    flex: 2,
                    sequence: 3
                },
                {
                    name: 'Description',
                    type: 'textarea',
                    path: 'description'
                },
                {
                    name: 'Specsheet Description',
                    type: 'text',
                    path: 'specsheetDescription',
                    suggestions: 0,
                    lineAfter: true
                },
                {
                    name: 'Selling Points',
                    type: 'list',
                    path: 'sellingPoints',
                    fields: [
                        {
                            name: 'Selling Point',
                            path: 'sellingPoint',
                            suggestions: 0
                        }
                    ]
                },
                {
                    name: 'Key',
                    type: 'text',
                    path: 'key',
                    suggestions: 0
                },
                {
                    name: 'Additional Details',
                    type: 'textarea',
                    path: 'additionalDetails'
                }
            ]
        },
        {
            type: 'category',
            name: 'Media',
            fields: [
                {
                    name: 'Specsheet Logo (.png)',
                    type: 'text',
                    path: 'temp.specsheetLogoUrl',
                    suggestions: 0
                },
                {
                    name: 'Logo',
                    type: 'img',
                    path: 'images.logo',
                    sequence: 1
                },
                {
                    name: 'Additional Images',
                    type: 'list',
                    path: 'images.additional',
                    fields: [
                        {
                            name: 'Image',
                            type: 'img',
                            path: 'image'
                        }
                    ]
                }
            ]
        },
        {
            type: 'category',
            name: 'Kiosk Content',
            fields: [
                {
                    name: 'Kiosk Title (defaults to Name specified above)',
                    type: 'text',
                    path: 'kiosk.title',
                    suggestions: 0
                },
                {
                    name: 'Kiosk Logo (defaults to Logo specified above)',
                    type: 'img',
                    path: 'kiosk.logo'
                },
                {
                    name: 'Kiosk Media Filename (.mp4, .png, .jpg)',
                    type: 'text',
                    path: 'kiosk.mediaFilename',
                    suggestions: 0
                },
                {
                    name: 'Kiosk Document Title',
                    type: 'text',
                    path: 'kiosk.documentTitle',
                    width: 50
                },
                {
                    name: 'Kiosk Document URL',
                    type: 'url',
                    path: 'kiosk.documentUrl',
                    width: 50
                }
            ]
        }
    ]
});
