// import { schemaDev } from './dev.js';
import { schemaFeature } from './feature.js';
import { schemaOem } from './oem.js';
import { schemaLocation, schemaDealerLocation } from './location.js';
import { schemaModel } from './model.js';
import { schemaModelSeries } from './modelSeries.js';
import { schemaFaq } from './faq.js';
import { schemaKiosk, schemaKioskType, schemaKioskAnalytics } from './kiosk.js';
import { schemaFile } from './file.js';
import { schemaUser } from './user.js';
import { schemaChange } from './change.js';
import { schemaAsset } from './asset.js';
import { schemaAssetCategory } from './assetCategory.js';
import { schemaWarrantyRecord } from './warranty.js';
import { schemaNotification } from './notification.js';

export const schemas = {
    // dev: schemaDev,
    features: schemaFeature,
    oem: schemaOem,
    location: schemaLocation,
    dealerLocation: schemaDealerLocation,
    model: schemaModel,
    modelSeries: schemaModelSeries,
    faq: schemaFaq,
    kiosk: schemaKiosk,
    kioskType: schemaKioskType,
    kioskAnalytics: schemaKioskAnalytics,
    file: schemaFile,
    user: schemaUser,
    change: schemaChange,
    assetCategory: schemaAssetCategory,
    warrantyRecord: schemaWarrantyRecord,
    asset: schemaAsset,
    notification: schemaNotification
};

// console.log(schemas);

export {
    // schemaDev,
    schemaFeature,
    schemaOem,
    schemaLocation,
    schemaDealerLocation,
    schemaModel,
    schemaModelSeries,
    schemaFaq,
    schemaKiosk,
    schemaKioskType,
    schemaKioskAnalytics,
    schemaFile,
    schemaUser,
    schemaChange,
    schemaAssetCategory,
    schemaWarrantyRecord,
    schemaAsset,
    schemaNotification
}

/**
 * Use this function to wrap the schema config in each schema file
 */
export function createSchema(schemaConfig) {
    const allFields = indexFields(schemaConfig.fields);
    
    /** default array of fields that will be active table headers on the collection page */
    const defaultColumns = Object.values(allFields)
        .filter(field => typeof field.sequence == 'number')
        .sort((a, b) => a.sequence - b.sequence)
        .map(column => ([column.fullPath, column]));
    
    const firstColumnPath = defaultColumns[0][0];
    const defaultSort = schemaConfig.defaultSort
        || !!allFields['updateTime']
            ? {updateTime: -1}
            : {[firstColumnPath]: 1};
    
    return {
        ...schemaConfig,
        allFields,
        defaultColumns,
        defaultSort
    }
}

function indexFields(fields, parentPath = '') {
    let indexedFields = {};
    
    for (const field of fields) {
        if (field.path) {
            field.fullPath = parentPath + field.path;
            indexedFields[field.fullPath] = field;
        }
        
        if (field.type == 'list') {
            parentPath += field.path+'.';
        }
        
        if ((field.fields) && (field.fields.length > 0)) {
            indexedFields = {...indexedFields, ...indexFields(field.fields, parentPath)};
        }
    }

    return indexedFields;
}
